<template>
  <div class="one">
    <Header> 修改登录密码 </Header>
    <div class="nei">
      <div class="box">
        <input
          v-model="passwordOne"
          type="password"
          id="pwd_one"
          maxlength="20"
          placeholder="请输入原密码"
        />
        <img :src="closeEye" alt="" @click="one" id="eye_one" />
      </div>
      <div class="box">
        <input
          v-model="password"
          type="password"
          id="pwd"
          maxlength="20"
          placeholder="请输入您的登录密码"
        />
        <img :src="closeEye" alt="" @click="close" id="eye" />
      </div>
      <div class="box">
        <input
          v-model="passwordTwo"
          type="password"
          id="twice"
          maxlength="20"
          placeholder="请输入您的登录密码"
        />
        <img :src="closeEye" alt="" @click="again" id="twice_img" />
      </div>
    </div>
    <div class="text">6-20位密码，数字或者字母</div>
    <div class="but" @click="but">确定</div>
  </div>
</template>
<script>
import closeEye from '@/assets/images/close_eye.png'; //闭眼
import openEye from '@/assets/images/open_eye.png'; //睁眼
export default {
  data() {
    return {
      closeEye: closeEye,
      openEye: openEye,
      flag: 0,
      num: 0,
      seven: 0,
      password: '',
      passwordTwo: '',
      passwordOne: '',
    };
  },
  methods: {
    // 确认
    but() {
      if (!this.passwordOne) return this.$toast('请输入原密码');
      if (!this.password) return this.$toast('请输入新密码');
      if (this.passwordTwo !== this.password)
        return this.$toast('两次密码输入不一致');
      let params = {
        password_name: this.passwordOne,
        password_repeat: this.password,
        password: this.passwordTwo,
        token: localStorage.getItem('token'),
      };
      this.$api.anyPassword(params).then((res) => {
        if (res.code == 0) {
          this.$toast(res.msg);
          this.$router.push('/Safety');
        } else {
          this.$toast(res.msg);
        }
      });
    },
    one() {
      let eye_one = document.getElementById('eye_one');
      let pwd_one = document.getElementById('pwd_one');
      if (this.seven == 0) {
        pwd_one.type = 'text';
        eye_one.src = openEye;
        this.seven = 1; //赋值操作
      } else {
        pwd_one.type = 'password';
        eye_one.src = closeEye;
        this.seven = 0;
      }
    },
    close() {
      let eye = document.getElementById('eye');
      let pwd = document.getElementById('pwd');
      if (this.flag == 0) {
        pwd.type = 'text';
        eye.src = openEye;
        this.flag = 1; //赋值操作
      } else {
        pwd.type = 'password';
        eye.src = closeEye;
        this.flag = 0;
      }
    },

    again() {
      let twice_img = document.getElementById('twice_img');
      let twice = document.getElementById('twice');
      if (this.num == 0) {
        twice.type = 'text';
        twice_img.src = openEye;
        this.num = 1; //赋值操作
      } else {
        twice.type = 'password';
        twice_img.src = closeEye;
        this.num = 0;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.text {
  color: #999999;
  font-size: 11px;
  margin-left: 34px;
  margin-top: 10px;
}
.box {
  width: 341px;
  display: flex;
  align-items: center;
  position: relative;
  #pwd {
    font-size: 13px;
    width: 100%;
    height: 60px;
    border: 0;
    padding-left: 18px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  #pwd_one {
    font-size: 13px;
    width: 100%;
    height: 60px;
    border: 0;
    padding-left: 18px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  #twice {
    font-size: 13px;
    width: 100%;
    height: 60px;
    border: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 18px;
  }
  #eye_one {
    width: 16px;
    height: 8px;
    position: absolute;
    right: 20px;
  }
  #twice_img {
    width: 16px;
    height: 8px;
    position: absolute;
    right: 20px;
  }
  #eye {
    width: 16px;
    height: 8px;
    position: absolute;
    right: 20px;
  }
}

.one {
  height: 100vh;
  background-color: #f3f4f8;
  .nei {
    padding-top: 10px;
    margin-left: 14px;
  }
}
.but {
  background-color: #0754d3;
  width: 341px;
  height: 44px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  line-height: 44px;
  margin-left: 17px;
  margin-top: 42px;
}
.topHeader {
  margin-bottom: 20px;
}
</style>
